<script setup>
import {onMounted, ref} from 'vue';
import Wizard from '@/components/Wizard';
import WizardStep from '@/components/WizardStep';
import WizardButtons from '@/components/WizardButtons';
import WizardNextButton from '@/components/WizardNextButton';
import WizardPreviousButton from '@/components/WizardPreviousButton';
import WizardStepContent from '@/components/WizardStepContent';
import {Form} from 'vee-validate';
import Popup from '@/clientcomponents/Popup.vue';
import Tooltip from '@/components/Tooltip.vue';
import utils from '../utils.js';
import Icon from '@/components/Icon';
import CustomTitle from '@/components/Title';
import DropdownItem from '@/components/DropdownItem.vue';
import Dropdown from '@/components/DropdownV2.vue';
import FormCheckbox from '@/components/FormCheckbox.vue';
import {useMutation, useQuery} from '@vue/apollo-composable';
import notify from '@/notify';
import { simpleLocale, t } from '@/i18n';
import {gql} from '@apollo/client/core';
import FormInput from '@/components/FormInput.vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import validate from '@/validate';
import { useRouter } from 'vue-router';
import Loader from '@/loader.js';

const props = defineProps({
    organization: {
        type: Object,
        required: true,
    },
    isShown: {
        type: Boolean,
        required: true,
    },
    close: {
        type: Function,
        required: true,
    },
});

const store = useStore();
const router = useRouter();
const { locale } = useI18n();
const loadingSoftware = ref(true);
const softwareList = ref([]);
const resellerList = ref([]);

const emits = defineEmits(['update:organization']);

const softwareSelection = ref('');
const resellerSelection = ref('');
const softwareCustomerReference = ref('');
const exactOnlineMailbox = ref('');
const customSoftware = ref('');
const customReseller = ref('');
const customMadeSoftwareId = ref(null);
const enDirectResellerId = ref(null);

async function getSoftwareList () {
    return new Promise((resolve, reject) => {
        const {result, onResult, onError} = useQuery(gql`
          query softwareList {
            softwareList {
              results {
                id,
                name,
                slug,
                isPublic,
                requiresCustomerReference
              }
            }
          }
        `);
        onResult(() => {
            softwareList.value = result.value.softwareList.results;
            customMadeSoftwareId.value = softwareList.value.find(s => s.slug === 'custom-made')?.id;
            loadingSoftware.value = false;
            resolve();
        });

        onError((error) => {
            notify.error(t('err-unknown'));
            reject(error);
        });
    });
}

async function getResellerBySoftware (softwareId) {
    return new Promise((resolve, reject) => {
        const {result, onResult, onError} = useQuery(gql`
          query resellerBySoftware($softwareId: String) {
            resellerBySoftware(softwareId:$softwareId) {
              id,
              name,
              slug,
              type
            }
          }
        `, {
            softwareId: softwareId,
        });

        onResult(() => {
            resolve(result.value.resellerBySoftware);
        });

        onError((error) => {
            notify.error(t('err-unknown'));
            reject(error);
        });
    });
}

onMounted(() => {
    getSoftwareList();
});

async function submitSoftwareStep (nextStep, values) {
    const selectedSoftware = softwareList.value.find(s => s.id === softwareSelection.value);
    resellerList.value = selectedSoftware.id !== 'other' ? await getResellerBySoftware(selectedSoftware.id) : [];
    enDirectResellerId.value = resellerList.value.find(r => r.slug === 'en-direct')?.id;

    if (isExactOnline()) {
        exactOnlineMailbox.value = values.exactMailbox;
    } else if (requiresReference()) {
        softwareCustomerReference.value = values.customerReference;
    }

    nextStep();
}

function requiresReference () {
    if (!softwareSelection.value) return false;
    return softwareList.value.find(s => s.id === softwareSelection.value)?.requiresCustomerReference;
}

function isExactOnline () {
    if (!softwareSelection.value) return false;
    return softwareList.value.find(s => s.id === softwareSelection.value)?.slug === 'exactonline';
}

async function addNewEnvironment () {
    try {
        Loader.start();
        const { mutate: addNewEnvMutation } = await useMutation(gql`
            mutation AddNewEnvironment($input: AddNewEnvironmentInput) {
              addNewEnvironment(input: $input) {
                errors {
                  code
                  detail
                  source {
                    pointer
                  }
                }
                environment {
                  id
                }
              }
            }
        `);
        const res = (await addNewEnvMutation({
            input: {
                accountantId: props.organization.id,
                resellerId: resellerSelection.value,
                softwareId: softwareSelection.value,
                name: softwareList.value.find(s => s.id === softwareSelection.value).name,
                language: simpleLocale(locale.value),
                softwareCustomerReference: softwareCustomerReference.value,
            },
        }));
        const selectedSoftware = softwareList.value.find(s => s.id === softwareSelection.value);
        const selectedReseller = resellerList.value.find(r => r.id === resellerSelection.value);
        await sendSignupEmail(props.organization, selectedReseller, selectedSoftware.name);

        const environmentId = res.data.addNewEnvironment.environment.id;
        await createCompany(props.organization, store.state.user.email, environmentId, simpleLocale(locale.value));

        props.close();
        emits('update:organization');
        router.push({ name: 'dashboard', params: { environmentId } });
    } catch (err) {
        await validate.notifyErrors(err);
    }
    Loader.stop();
}

async function createCompany (organization, contactEmail, environmentId, language) {
    try {
        const { mutate: newClientMutation } = await useMutation(gql`
            mutation newClient($input: NewClientInput!) {
              newClient(input: $input) {
                data {
                  id
                  enterpriseNumber
                  clientCode
                }
                errors {
                  code
                  detail
                  source {
                        pointer
                    }
                }
              }
            }
        `);
        const res = await newClientMutation({
            'input': {
                'address': organization.address,
                'address2': organization.address2,
                'city': organization.city,
                'clientCode': organization.enterpriseNumber,
                'contactEmail': contactEmail,
                'enterpriseName': organization.enterpriseName,
                'enterpriseNumber': organization.enterpriseNumber,
                'fiduciaryId': environmentId,
                'hasBelgianVatNumber': true,
                'language': language,
                'representativeFunction': organization.representativeFunction,
                'representativeName': organization.representativeName,
                'sendCodaAndSodaByMail': false,
                'vatNumber': organization.enterpriseNumber,
                'zip': organization.zip,
                'exactEmail': exactOnlineMailbox.value || null,
            }});
        if (res.errors) {
            notify.error(t('err-unknown'));
            return;
        }
    } catch (e) {
        notify.error(t('err-unknown'));
    }
}

async function sendSignupEmail (organization, reseller, softwareName) {
    const { mutate: sendSignupEmailMutation } = await useMutation(gql`
        mutation SendSignupEmailAfterEnvCreation($input: SignupEmailInput!) {
            sendSignupEmailAfterEnvCreation(input: $input) {
                errors {
                    code
                    detail
                    source {
                        pointer
                    }
                }
            }
        }
    `);
    let invoiceMethod;
    if (reseller.slug === 'sage') {
        invoiceMethod = 'sagebob';
    } else {
        invoiceMethod = reseller.type;
    }
    invoiceMethod ||= 'direct';  // reseller.type can be null
    try {
        const res = (await sendSignupEmailMutation({input: {
            legalRepName: organization.representativeName,
            legalRepEmail: organization.representativeEmailAddress,
            legalRepFunction: organization.representativeFunction,
            enterpriseNumber: organization.enterpriseNumber,
            organizationName: organization.enterpriseName,
            organizationAddress: [organization.address, organization.address2].filter(e => e),  // remove the null values
            organizationZip: organization.zip,
            organizationCity: organization.city,
            software: softwareName,
            reseller: reseller.name,
            invoiceEmail: organization.invoicingEmailAddress,
            invoiceMethod,
            language: simpleLocale(locale.value),
        }})).data.sendSignupEmailAfterEnvCreation;

        if (res.errors) {
            notify.error(t('err-unknown'));
            return;
        }
    } catch (error) {
        notify.error(t('err-unknown'));
    }
}

function softwareStepValid (values) {
    if (isExactOnline()) {
        return (values.exactMailbox && utils.validateExactEmail(values.exactMailbox));
    } else if (requiresReference()) {
        return values.customerReference;
    } else if (softwareSelection.value) {
        return true;
    }
    return false;
}

</script>

<template>
    <Popup :show='props.isShown' :close='props.close'>
        <template #header>
            {{ $t('add-new-environment.popup.title') }}
        </template>
        <Wizard v-slot='{ previousStep, nextStep }' full-width>
            <WizardStep
                title='add-new-environment.steps.software.title'
                icon='Cube'
                class='flex flex-col'
            >
                <Form
                    tag='div'
                    v-slot='{ values, handleSubmit }'
                    class='flex flex-col grow'
                >
                    <WizardStepContent bordered class='h-full flex flex-col grow'>
                        <CustomTitle :level='2' :style-of='3' variant='light' no-default-margins>
                            {{ $t('add-new-environment.steps.software.content') }}
                        </CustomTitle>
                        <Tooltip faded class='self-start'>
                            <template #trigger>
                                <div class='flex items-center gap-1 text-grey-500'>
                                    <Icon name='InformationCircle' family='outline' />
                                    {{ $t('add-new-environment.steps.software.tooltip-trigger') }}
                                </div>
                            </template>
                            <template #content>
                                {{ $t('add-new-environment.steps.software.tooltip-content') }}
                            </template>
                        </Tooltip>
                        <Transition>
                            <Dropdown
                                v-model='softwareSelection'
                                class='mt-6 z-10'
                                default-value='allstates'
                                v-if='!loadingSoftware'
                            >
                                <DropdownItem
                                    v-for='software in softwareList'
                                    :id='software.id'
                                    :name='software.slug === "custom-made" ? $t("add-new-environment.steps.software.list-other") : software.name'
                                    :key='software.id'
                                    :class='{"border-b mb-3": software.slug === "custom-made"}'
                                >
                                    <div v-if='software.slug === "custom-made"' class='mb-2'>
                                        {{ $t('add-new-environment.steps.software.list-other') }}
                                    </div>
                                    <div v-else>
                                        {{ software.name }}
                                    </div>
                                </DropdownItem>
                            </Dropdown>
                        </Transition>
                        <FormInput
                            v-if='softwareSelection === customMadeSoftwareId'
                            name='customSoftware'
                            :label='$t("add-new-environment.steps.software.label-custom-software")'
                            rules='required|max:160'
                            :edit='true'
                            id='customSoftware'
                            class='mt-6'
                            :value='customSoftware'
                            @input='value => customSoftware = value'
                        />
                        <FormInput
                            v-if='softwareSelection && requiresReference()'
                            name='customerReference'
                            ref='customerReference'
                            :label='$t("add-new-environment.steps.software.label-software-customer-ref")'
                            rules='required|max:160'
                            :edit='true'
                            id='customerReference'
                            class='mt-6'
                        />
                        <FormInput
                            v-if='softwareSelection && isExactOnline()'
                            name='exactMailbox'
                            ref='exactMailbox'
                            :label='$t("add-new-environment.steps.software.label-exact-mailbox")'
                            rules='required|exactEmail|max:160'
                            :edit='true'
                            id='exactMailbox'
                            class='mt-6'
                        />
                        <WizardButtons>
                            <WizardNextButton
                                @click='handleSubmit($event, () => { submitSoftwareStep(nextStep, values)})'
                                id='env-software-next-step'
                                label='btn-continue'
                                :disabled='!softwareStepValid(values)'
                            />
                        </WizardButtons>
                    </WizardStepContent>
                </Form>
            </WizardStep>
            <WizardStep
                title='add-new-environment.steps.reseller.title'
                icon='User'
            >
                <WizardStepContent bordered class='h-full flex flex-col grow'>
                    <CustomTitle :level='2' :style-of='3' variant='light' no-default-margins>
                        {{ $t('add-new-environment.steps.reseller.content') }}
                    </CustomTitle>
                    <Tooltip
                        faded
                        class='self-start'
                    >
                        <template #trigger>
                            <div class='flex items-center gap-1 text-grey-500'>
                                <Icon name='InformationCircle' family='outline' />
                                {{ $t('add-new-environment.steps.software.tooltip-trigger') }}
                            </div>
                        </template>
                        <template #content>
                            {{ $t('add-new-environment.steps.reseller.tooltip-content') }}
                        </template>
                    </Tooltip>
                    <Dropdown
                        v-model='resellerSelection'
                        class='w-full mt-6'
                        default-value='allstates'
                    >
                        <DropdownItem
                            class='w-full'
                            v-for='option in resellerList'
                            :id='option.id'
                            :name='option.slug === "en-direct" ? $t("add-new-environment.steps.software.list-other") : option.name'
                            :key='option.id'
                            :class='{"border-b mb-3": resellerList.length > 1 && option.slug === "en-direct"}'
                        >
                            <div
                                v-if='option.slug === "en-direct"'
                                :class='{"mb-2": resellerList.length}'
                            >
                                {{ $t('add-new-environment.steps.software.list-other') }}
                            </div>
                            <div v-else>
                                {{ option.name }}
                            </div>
                        </DropdownItem>
                    </Dropdown>

                    <FormInput
                        v-if='resellerSelection === enDirectResellerId'
                        name='customReseller'
                        :label='$t("add-new-environment.steps.reseller.label-custom")'
                        rules='required|max:160'
                        :edit='true'
                        id='customReseller'
                        class='mt-6'
                        :value='customReseller'
                        @input='value => customReseller = value'
                    />

                    <WizardButtons>
                        <WizardPreviousButton
                            @click='previousStep'
                            label='add-new-environment.btn-previous'
                        />
                        <WizardNextButton
                            @click='nextStep'
                            id='env-reseller-next-step'
                            label='btn-continue'
                            :disabled='!resellerSelection'
                        />
                    </WizardButtons>
                </WizardStepContent>
            </WizardStep>
            <WizardStep
                title='add-new-environment.steps.terms-and-conditions.title'
                icon='ListBullet'
            >
                <WizardStepContent bordered class='h-full flex flex-col grow'>
                    <CustomTitle :level='2' :style-of='3' variant='light' no-default-margins>
                        {{ $t('add-new-environment.steps.terms-and-conditions.content') }}
                    </CustomTitle>
                    <Form
                        tag='div'
                        v-slot='{ values, handleSubmit }'
                        class='mt-6 flex flex-col grow'
                    >
                        <FormCheckbox
                            name='termsAndConditions'
                            :label='$t("add-new-environment.steps.terms-and-conditions.label-checkbox")'
                            edit
                        />
                        <WizardButtons>
                            <WizardPreviousButton
                                @click='previousStep'
                                label='add-new-environment.btn-previous'
                            />
                            <WizardNextButton
                                @click='handleSubmit($event, () => { addNewEnvironment(nextStep)})'
                                id='create-environment'
                                :disabled='!values.termsAndConditions'
                                label='add-new-environment.btn-add-new-env'
                            />
                        </WizardButtons>
                    </Form>
                </WizardStepContent>
            </WizardStep>
        </Wizard>
    </Popup>
</template>
