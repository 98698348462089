<script setup>
    import { computed, onMounted, ref, reactive, watch } from 'vue';
    import { apolloClient } from '@/apollo';
    import Icon from '@/components/Icon';
    import { gql } from '@apollo/client/core';
    import notify from '@/notify';
    import { t } from '@/i18n';

    const props = defineProps({
        organization: {
            type: Object,
            default: undefined,
        },
    });

    const ready = ref(false);

    // steps statuses
    const TODO = 'todo';
    const PENDING = 'pending';
    const DONE = 'done';

    const minimized = ref(false);

    const onboardingSteps = reactive([
        {
            name: t('onboarding-helper.step.sign.name'),
            id: 'sign',
            status: DONE,
        },
        {
            name: t('onboarding-helper.step.org.name'),
            id: 'org',
            status: TODO,
        },
        {
            name: t('onboarding-helper.step.env.name'),
            id: 'env',
            status: TODO,
        },
        {
            name: t('onboarding-helper.step.coda.name'),
            id: 'coda',
            status: TODO,
            faqLink: 'https://google.com',
        },
    ]);

    const currentStep = computed(() => {
        let currentStep;
        onboardingSteps.forEach(step => {
            if (step.status === TODO && !currentStep) {
                currentStep = step;
            }
        });
        return currentStep;
    });

    const completion = computed(() => {
        let completedSteps = 0;

        onboardingSteps.map(step => {
            if (step.status === DONE) {
                completedSteps += 1;
            }
        });

        return Math.floor(100 / onboardingSteps.length * completedSteps);
    });

    const SEARCH_CODA_MANDATES = gql`
        query SearchCodaMandates($input: SearchCodaMandatesInput) {
            searchCodaMandates(input: $input) {
                codaMandates {
                    state
                }
            }
        }
    `;

    async function hasCodaMandatesInState (states) {
        if (!props.organization || !props.organization.fiduciaries) {
            return false;
        }

        try {
            const { data } = await apolloClient.query({
                query: SEARCH_CODA_MANDATES,
                variables: {
                    input: {
                        paging: { limit: 1 },
                        data: {
                            fiduciaryIds: props.organization.fiduciaries.map(env => env.id),
                            states: states,
                        },
                    },
                },
            });

            return data.searchCodaMandates.codaMandates.length > 0;
        } catch {
            notify.error(t('err-unknown'));
            return false;
        }
    }

    async function defineStepStates () {
        // define org step state
        // we check the id but it could be anything else
        if (props.organization && props.organization.id) {
            onboardingSteps[1].status = DONE;
        } else {
            ready.value = true;
            return;
        }
        // define env step state
        if (props.organization.fiduciaries.length > 0) {
            onboardingSteps[2].status = DONE;
        } else {
            ready.value = true;
            return;
        }

        // Check Coda mandates
        const hasCodaMandatesInStateActive = await hasCodaMandatesInState(['active', 'signed', 'sent-hq']);
        if (hasCodaMandatesInStateActive) {
            onboardingSteps[3].status = DONE;
        }

        ready.value = true;
    }

    function getIcon (step) {
        if (step.id === currentStep.value.id) {
            return {
                color: 'text-green-500',
                name: 'ArrowRight',
            };
        } else if (step.status === PENDING) {
            return {
                color: 'text-orange-300',
                name: 'Clock',
            };
        } else if (step.status === TODO) {
            return {
                color: 'text-grey-250',
                name: 'ArrowRight',
            };
        } else {
            return {
                color: 'text-blue-500',
                name: 'Check',
            };
        }
    }

    function toggleMinimization () {
        minimized.value = !minimized.value;
    }

    watch(() => props.organization, () => {
        defineStepStates();
    });

    onMounted(async () => {
        defineStepStates();
    });
</script>

<template>
    <Transition>
        <div class='onboarding-helper' v-if='currentStep && ready'>
            <div class='flex flex-col px-6 pt-4 pb-3 border-b border-grey-200'>
                <div class='flex items-center justify-between mb-1'>
                    <div class='font-bold mr-6'>
                        {{ $t("onboarding-helper.title") }}
                    </div>
                    <div class='rounded-full bg-grey-200 grow h-2'>
                        <div class='h-full bg-blue-500 rounded-full' :style='`width: ${completion}%;`'></div>
                    </div>
                </div>
                <div class='flex items-center justify-between'>
                    <div>
                        <span :class='`${currentStep.status === PENDING ? "text-orange-400" : "text-green-500"}`'>
                            {{ $t("onboarding-helper.next") }}:
                        </span>
                        {{ currentStep.name }}
                    </div>
                    <div>
                        {{ `${completion}%` }}
                    </div>
                </div>
            </div>
            <div class='px-6 py-5 border-b border-grey-200 -ml-1' v-if='!minimized'>
                <div class='flex flex-col justify-center gap-4'>
                    <div
                        v-for='step in onboardingSteps'
                        :key='step.id'
                        class='flex gap-2 items-center'
                        :class='{active: step.id === currentStep.id, done: step.status === DONE}'
                        :id='`onboarding-helper-${step.id}`'
                    >
                        <Icon :name='getIcon(step).name' :class='getIcon(step).color' width='w-5' />
                        <span>
                            {{ step.name }}
                        </span>
                        <a
                            v-if='currentStep.faqLink && step.id === currentStep.id'
                            :href='currentStep.faqLink'
                            class='text-blue-500 ml-1 hover:text-blue-300 cursor-pointer flex items-center justify-center gap-1'
                            :id='`${currentStep.id}-tutorial`'
                        >
                            {{ $t('onboarding-helper.step.tell-me') }}
                            <Icon name='BookOpen' width='w-5' />
                        </a>
                    </div>
                </div>
            </div>
            <div class='flex justify-end items-center px-4 pt-4 pb-3'>
                <div
                    class='flex gap-1 items-center text-grey-300 cursor-pointer transition-all duration-75 transform ease-linear hover:translate-x-1 hover:translate-y-0.5 hover:text-blue-500 select-none'
                    @click='toggleMinimization'
                >
                    <div>
                        {{ minimized ? 'show more' : 'show less' }}
                    </div>
                    <Icon :name='minimized ? "ArrowUpLeft": "ArrowDownRight"' width='w-6' />
                </div>
            </div>
        </div>
    </Transition>
</template>

<style scoped>
.onboarding-helper {
    @apply fixed z-50 bottom-6 right-6 bg-white shadow-main rounded-md;
    width: 420px;
}

@keyframes bounce {
    0% {
        @apply -bottom-32;
        opacity: 0;
    }
    50% {
        opacity: 1;
        @apply bottom-10;
    }
    100% {
        @apply bottom-6;
    }
}

.v-enter-active,
.v-leave-active {
    animation: bounce .7s ease;
    opacity: 1;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
